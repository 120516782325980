import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
  const { user } = useContext(AuthContext);

  // return user ? <Outlet /> : <Navigate to="/login" />;

  const role = user?.role;

  // console.log(role);

  if (!role) {
    return <Navigate to="/login" />;
  }

  return children;
};
