import React, { useContext, useState } from "react";
import "./login.scss";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const { user, loading, dispatch } = useContext(AuthContext);

  const initialState = {
    email: "",
    password: "",
  };

  const credentials = {
    email,
    password,
  };

  const handleClick = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGIN_START" });
    try {
      const res = await axios.post("/login", credentials);
      dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
      navigate("/dashboard");
    } catch (err) {
      dispatch({
        type: "LOGIN_FAILURE",
        payload:
          err.response && err.response.data
            ? err.response.data
            : "Unknown error",
      });
      setError("Invalid email or password");
    }
  };

  return (
    <div className="__fw">
      <div className="__mw">
        <div className="login">
          <div className="_log_in">
            <div className="_in_h">
              <h2>Welcome Back</h2>
              <span>Login to continue</span>
            </div>
            <form onSubmit={handleClick}>
              <input
                required
                type="email"
                placeholder="your@email.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                required
                type="password"
                placeholder="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button>Login</button>
              {/* <div className="_rg_pg">
                <span>
                  Don't have an account yet?{" "}
                  <Link to={"/register"}> Register now</Link>
                </span>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
