import React, { useContext } from 'react'
import "./header.scss";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import SideBar from '../sideBar/SideBar';


const Header = () => {
  const { user, dispatch } = useContext(AuthContext);
     const navigate = useNavigate();

 
  const logoutUser = async () => {
    try {
      await axios.post("/logout");
      localStorage.removeItem("user");
      dispatch({ type: "LOGOUT" });
      navigate("/login");
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error("Error fetching data:", error);
      }
    }
  };

  return (
    <div className="header">
      <div className="header_in">
        <span>Notions Online Stocks Management</span>
      </div>
      {user ? (<div className="header_in">
        <button className="btn_logout" onClick={logoutUser}>
          Logout
        </button>
      </div>):("")}
    </div>
  );
}

export default Header
