import React, { useEffect, useState } from "react";
import "../../pages/inventory/inventory.scss";
import "./addNew.scss";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FaSearch } from "react-icons/fa";
import axios from "axios";
import TextSlide from "../../components/textSlide/TextSlide";
import Table from "../../components/table/Table";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { FaRegEye } from "react-icons/fa";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import List from "../list/List";
import * as XLSX from "xlsx";
import { IoCheckmarkCircle } from "react-icons/io5";
import { IoIosCloseCircle } from "react-icons/io";

const AddNew = () => {
  const { itemId } = useParams();
  const [showAddNew, setShowAddNew] = useState(false);
  const [showPro, setShowPro] = useState(false);
  const [productData, setProductData] = useState([]);
  const [saveData, setSaveData] = useState([]);
  const [itemEditId, setItemEditId] = useState("");
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [weight, setWeight] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [sku, setSku] = useState("");
  const [img, setImg] = useState([]);
  const [brand, setBrand] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [discount, setDiscount] = useState("");
  const [singalProductData, setSingalProductData] = useState([]);
  const [addedPhotos, setAddedPhotos] = useState([]);
  const [addedPhotosHD, setAddedPhotosHD] = useState([]);
  const [image, setImage] = useState([]);
  const navigate = useNavigate();
  const [subCategory, setSubCategory] = useState("");
  const [flavour, setFlavour] = useState("");
  const [barcodePouch, setBarcodePouch] = useState("");
  const [barcodeCase, setBarcodeCase] = useState("");
  const [uom, setUom] = useState("");
  const [crudeFiber, setCrudeFiber] = useState("");
  const [satureatedFat, setSatureatedFat] = useState("");
  const [protein, setProtein] = useState("");
  const [fat, setFat] = useState("");
  const [ctnWidthMM, setCtnWidthMM] = useState("");
  const [ctnHeightMM, setCtnHeightMM] = useState("");
  const [unit, setUnit] = useState("");
  const [ctnLengthMM, setCtnLengthMM] = useState("");
  const [base, setBase] = useState("");
  const [type, setType] = useState("");
  const [filling, setFilling] = useState("");
  const [brandOwner, setBrandOwner] = useState("");
  const [coating, setCoating] = useState("");
  const [wetRang, setWetRang] = useState("");
  const [nameEnglish, setNameEnglish] = useState("");
  const [nameArbic, setNameArbic] = useState("");
  const [claimsOne, setClaimsOne] = useState("");
  const [proDesEnglish, setProDesEnglish] = useState("");
  const [proDesArbic, setProDesArbic] = useState("");
  const [claimsTwo, setClaimsTwo] = useState("");
  const [claimsThree, setClaimsThree] = useState("");
  const [benefits, setBenefits] = useState("");
  const [carbohydrates, setCarbohydrates] = useState("");
  const [sugar, setSugar] = useState("");
  const [energy, setEnergy] = useState("");
  const [ingredients, setIngredients] = useState("");
  const [heightMM, setHeightMM] = useState("");
  const [widthMM, setWidthMM] = useState("");
  const [lengthMM, setLengthMM] = useState("");
  const [shelfLife, setShelfLife] = useState("");
  const [countryOfOrigin, setCountryOfOrigin] = useState("");
  const [storageInstructions, setStorageInstructions] = useState("");
  const [dimensionsPerPack, setDimensionsPerPack] = useState("");
  const [dimensionsPerCase, setDimensionsPerCase] = useState("");
  const [featuresAndOrBenefitsOne, setFeaturesAndOrBenefitsOne] = useState("");
  const [featuresAndOrBenefitsTwo, setFeaturesAndOrBenefitsTwo] = useState("");
  const [loading, setLoading] = useState(false);
  const [approve_one, setApprove_one] = useState(false);
  const [approve_two, setApprove_two] = useState(false);
  const [approve_three, setApprove_three] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      title,
      price,
      weight,
      unit,
      type,
      category,
      subCategory,
      brand,
      brandOwner,
      base,
      filling,
      coating,
      wetRang,
      sku,
      nameEnglish,
      nameArbic,
      barcodePouch,
      barcodeCase,
      claimsOne,
      claimsTwo,
      claimsThree,
      benefits,
      proDesEnglish,
      proDesArbic,
      dimensionsPerCase,
      storageInstructions,
      shelfLife,
      widthMM,
      heightMM,
      lengthMM,
      ctnWidthMM,
      ctnHeightMM,
      ctnLengthMM,
      ingredients,
    };
    try {
      await axios.post("/saveProduct", data).then((response) => {
        setSaveData(response.data);
      });

      //clear form
      setUnit("");
      setType("");
      setShowAddNew("");
      setTitle("");
      setPrice("");
      setWeight("");
      setCategory("");
      setSku("");
      setBrand("");
      setClaimsOne("");
      setClaimsTwo("");
      setClaimsThree("");
      setBrandOwner("");
      setAddedPhotosHD("");
      setDiscount("");
      setDescription("");
      setAddedPhotos("");
      setSubCategory("");
      setNameEnglish("");
      setNameArbic("");
      setFilling("");
      setCoating("");
      setProDesEnglish("");
      setBarcodePouch("");
      setBarcodeCase("");
      setBenefits("");
      setCrudeFiber("");
      setSatureatedFat("");
      setProtein("");
      setFat("");
      setWetRang("");
      setCarbohydrates("");
      setSugar("");
      setEnergy("");
      setIngredients("");
      setHeightMM("");
      setWidthMM("");
      setLengthMM("");
      setCtnLengthMM("");
      setCtnHeightMM("");
      setCtnWidthMM("");
      setShelfLife("");
      setCountryOfOrigin("");
      setStorageInstructions("");
      setDimensionsPerPack("");
      setDimensionsPerCase("");
      setFeaturesAndOrBenefitsOne("");
      setFeaturesAndOrBenefitsTwo("");
    } catch (error) {
      console.log(error);
    }
  };

  async function uploadPhoto(e) {
    const files = e.target.files;
    const data = new FormData();

    for (let i = 0; i < files.length; i++) {
      data.append("photos", files[i]);
    }
    setLoading(true); //!here will be bracket
    try {
      await axios
        .post("/upload", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const { data: filenames } = response;
          setAddedPhotos((prev) => {
            return [...prev, ...filenames];
          });
        });
    } catch (error) {
      console.error("Image upload failed:", error);
    } finally {
      setLoading(false);
    }
  }
  async function uploadPhotoHD(e) {
    const files = e.target.files;
    const data = new FormData();

    for (let i = 0; i < files.length; i++) {
      data.append("photos", files[i]);
    }
    await axios
      .post("/uploadHD", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const { data: filenames } = response;
        setAddedPhotosHD((prev) => {
          return [...prev, ...filenames];
        });
      });
  }
  function removePhoto(e, filename) {
    e.preventDefault();
    setAddedPhotos([...addedPhotos.filter((photo) => photo !== filename)]);
  }
  function selectMainPhoto(e, filename) {
    e.preventDefault();
    const addPhotoWithoutSelected = addedPhotos.filter(
      (photo) => photo !== filename
    );
    const newAddedPhotos = [filename, ...addPhotoWithoutSelected];
    setAddedPhotos(newAddedPhotos);
  }

  function removePhotoHD(e, filename) {
    e.preventDefault();
    setAddedPhotosHD([...addedPhotosHD.filter((photo) => photo !== filename)]);
  }
  function selectMainPhotoHD(e, filename) {
    e.preventDefault();
    const addPhotoWithoutSelected = addedPhotosHD.filter(
      (photo) => photo !== filename
    );
    const newAddedPhotos = [filename, ...addPhotoWithoutSelected];
    setAddedPhotosHD(newAddedPhotos);
  }
  const handleAddNew = () => {
    setShowAddNew(!showAddNew);
  };

  const approve = "approve_1";

  const handleApproveOne =async ()=>{
    setApprove_one(true)

    const data = {
      approve_one,
      sku,
    };

    try {
      await axios.post('/approve-item',data)

    } catch (error) {
      console.log(error)
      
    }
  }
  const handleApproveTwo = () => {
    setApprove_two(true);
  };
  const handleApproveThree = () => {
    setApprove_three(true);
  };

  return (
    <div className="inventory_main">
      <div className="inventory">
        <div className="ad_new">
          <div className="addNew_in">
            <div className="addNew_wrap">
              <h2>Add New Product</h2>

              <br />

              <form onSubmit={handleSubmit}>
                <div className="add_form">
                  <div className="add_form_wrap">
                    <div>
                      <div className="input">
                        <label>01. Title</label>
                        <input
                          type="text"
                          placeholder="Chocodate"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                      <div className="input">
                        <label>Price</label>
                        <input
                          type="text"
                          placeholder="100 AED"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                        />
                      </div>
                      <div className="input">
                        <label>Weight</label>
                        <input
                          type="text"
                          placeholder="500g"
                          value={weight}
                          onChange={(e) => setWeight(e.target.value)}
                        />
                      </div>
                      <div className="input">
                        <label>Unit</label>
                        <select
                          value={unit}
                          onChange={(e) => setUnit(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="ACRYLIC">ACRYLIC</option>
                          <option value="BOX">BOX</option>
                          <option value="BULK">BULK</option>
                          <option value="CUBE">CUBE</option>
                          <option value="JAR">JAR</option>
                          <option value="POUCH">POUCH</option>
                          <option value="T BOX">T BOX</option>
                        </select>
                      </div>

                      <div className="input">
                        <label>Type</label>
                        <select
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="BULK">BULK</option>
                          <option value="PROM">PROM</option>
                          <option value="REG">REG</option>
                        </select>
                      </div>

                      <div className="input">
                        <label>Category</label>
                        <select
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="BAKING">BAKING</option>
                          <option value="CHOCOLATE">CHOCOLATE</option>
                          <option value="CHOCOLATE DATES">
                            CHOCOLATE DATES
                          </option>
                          <option value="COATEED NUTS">COATEED NUTS</option>
                          <option value="ENERGY & PROTIEN">
                            ENERGY & PROTIEN
                          </option>
                          <option value="PLAIN DATE">PLAIN DATE</option>
                        </select>
                      </div>

                      <div className="input">
                        <label>Sub Category</label>
                        <select
                          value={subCategory}
                          onChange={(e) => setSubCategory(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="BUTTER">BUTTER</option>
                          <option value="CHOCOLATE">CHOCOLATE</option>
                          <option value="CHOCOLATE APRICOT">
                            CHOCOLATE APRICOT
                          </option>
                          <option value="CHOCOLATE CHIPS">
                            CHOCOLATE CHIPS
                          </option>
                          <option value="CHOCOLATE FIG">CHOCOLATE FIG</option>
                          <option value="COATED NUTS">COATED-NUTS</option>
                          <option value="DATE CHOCO">DATE-CHOCO</option>
                          <option value="FLOUR">FLOUR</option>
                          <option value="PLAIN DATE">PLAIN DATE</option>
                          <option value="PROTEIN BALL">PROTEIN BALL</option>
                          <option value="SUGAR">SUGAR</option>
                        </select>
                      </div>

                      <div className="input">
                        <label>Brand</label>
                        <select
                          value={brand}
                          onChange={(e) => setBrand(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="ALDI">ALDI</option>
                          <option value="APRONS N LOVE">APRONS N LOVE</option>
                          <option value="ARABIAN DELIGHTS">
                            ARABIAN DELIGHTS
                          </option>
                          <option value="ATT">ATT</option>
                          <option value="CARREFOUR">CARREFOUR</option>
                          <option value="CHOCODATE">CHOCODATE</option>
                          <option value="DARA">DARA</option>
                          <option value="FADE FIT">FADE FIT</option>
                          <option value="FIESTA">FIESTA</option>
                          <option value="HERITAGE">HERITAGE</option>
                          <option value="KITCHEN & LOVE">KITCHEN & LOVE</option>
                          <option value="LULU">LULU</option>
                          <option value="MARS">MARS</option>
                          <option value="MOJO">MOJO</option>
                          <option value="OTHERS">OTHERS</option>
                          <option value="ROUGE">ROUGE</option>
                          <option value="SPINNEYS">SPINNEYS</option>
                          <option value="VENICE">VENICE</option>
                          <option value="WISHES">WISHES</option>
                          <option value="ZEIN">ZEIN</option>
                        </select>
                      </div>

                      <div className="input">
                        <label>Brand Owner</label>
                        <select
                          value={brandOwner}
                          onChange={(e) => setBrandOwner(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="NOTIONS">NOTIONS</option>
                          <option value="PRIVATE LABEL">PRIVATE LABEL</option>
                        </select>
                      </div>

                      <div className="input">
                        <label>Base</label>
                        <select
                          value={base}
                          onChange={(e) => setBase(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="ALMOND">ALMOND</option>
                          <option value="APRICOT">APRICOT</option>
                          <option value="BUTTER">BUTTER</option>
                          <option value="CHOCOLATE">CHOCOLATE</option>
                          <option value="CHOCOLATE CHIPS">
                            CHOCOLATE CHIPS
                          </option>
                          <option value="DATE">DATE</option>
                          <option value="DATE PASTE">DATE PASTE</option>
                          <option value="DATES">DATES</option>
                          <option value="FIG">FIG</option>
                          <option value="FLOUR">FLOUR</option>
                          <option value="HAZELNUT">HAZELNUT</option>
                          <option value="PLAIN">PLAIN</option>
                          <option value="PROTEIN BALL">PROTEIN BALL</option>
                          <option value="SUGAR">SUGAR</option>
                        </select>
                      </div>

                      <div className="input">
                        <label>Filling </label>
                        <select
                          value={filling}
                          onChange={(e) => setFilling(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="0">0</option>
                          <option value="ALMOND">ALMOND</option>
                          <option value="ASSORTED">ASSORTED</option>
                          <option value="BERRY">BERRY</option>
                          <option value="CARAMEL">CARAMEL</option>
                          <option value="CHOCOLATE CHIPS">
                            CHOCOLATE CHIPS
                          </option>
                          <option value="CHOCOLATE">CHOCOLATE</option>
                          <option value="CHOCOLATES">CHOCOLATES</option>
                          <option value="COCONUT">COCONUT</option>
                          <option value="FRUITS">FRUITS</option>
                          <option value="HAZELNUTS">HAZELNUTS</option>
                          <option value="HAZELNUT">HAZELNUT</option>
                          <option value="MOUSSE">MOUSSE</option>
                          <option value="NUTS">NUTS</option>
                          <option value="ORANGE">ORANGE</option>
                          <option value="PEANUT">PEANUT</option>
                          <option value="PEANUT BUTTER">PEANUT BUTTER</option>
                          <option value="PISTACHIO">PISTACHIO</option>
                          <option value="PLAIN">PLAIN</option>
                          <option value="TRUFFLE">TRUFFLE</option>
                        </select>
                      </div>

                      <div className="input">
                        <label>Coating </label>
                        <select
                          value={coating}
                          onChange={(e) => setCoating(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="ALMOND">ALMOND</option>
                          <option value="ASSORTED">ASSORTED</option>
                          <option value="BUTTERSCOTCH">BUTTERSCOTCH</option>
                          <option value="CARAMEL">CARAMEL</option>
                          <option value="DARK">DARK</option>
                          <option value="EXTRA DARK">EXTRA DARK</option>
                          <option value="HAZELNUT">HAZELNUT</option>
                          <option value="COCONUT">COCONUT</option>
                          <option value="MANGO">MANGO</option>
                          <option value="MILK">MILK</option>
                          <option value="NAS">NAS</option>
                          <option value="ORANGE">ORANGE</option>
                          <option value="PEANUT">PEANUT</option>
                          <option value="PISTACHIO">PISTACHIO</option>
                          <option value="PLAIN">PLAIN</option>
                          <option value="RUBY">RUBY</option>
                          <option value="SEMI SWEET">SEMI SWEET</option>

                          <option value="WHITE">WHITE</option>
                        </select>
                      </div>
                      <div className="input">
                        <label>WET Rang</label>
                        <select
                          value={wetRang}
                          onChange={(e) => setWetRang(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="BULK">BULK</option>
                          <option value="L">L</option>
                          <option value="M">M</option>
                          <option value="MINI">MINI</option>
                          <option value="S">S</option>
                        </select>
                      </div>

                      <div className="input">
                        <label>SKU</label>
                        <input
                          type="text"
                          placeholder="LR 123456"
                          value={sku}
                          onChange={(e) => setSku(e.target.value)}
                        />
                      </div>

                      <div className="input">
                        <label>SKU Name English</label>
                        <input
                          type="text"
                          placeholder=""
                          value={nameEnglish}
                          onChange={(e) => setNameEnglish(e.target.value)}
                        />
                      </div>
                      <div className="input">
                        <label>SKU Name Arbic</label>
                        <input
                          type="text"
                          placeholder=""
                          value={nameArbic}
                          onChange={(e) => setNameArbic(e.target.value)}
                        />
                      </div>
                      <div className="input">
                        <label>Barcode - Pouch</label>
                        <input
                          type="text"
                          placeholder=""
                          value={barcodePouch}
                          onChange={(e) => setBarcodePouch(e.target.value)}
                        />
                      </div>
                      <div className="input">
                        <label>Barcode - Case</label>
                        <input
                          type="text"
                          placeholder=""
                          value={barcodeCase}
                          onChange={(e) => setBarcodeCase(e.target.value)}
                        />
                      </div>
                      <div className="input">
                        <label>Claims 1</label>
                        <input
                          type="text"
                          placeholder=""
                          value={claimsOne}
                          onChange={(e) => setClaimsOne(e.target.value)}
                        />
                      </div>
                      <div className="input">
                        <label>Claims 2</label>
                        <input
                          type="text"
                          placeholder=""
                          value={claimsTwo}
                          onChange={(e) => setClaimsTwo(e.target.value)}
                        />
                      </div>
                      <div className="input">
                        <label>Claims 3</label>
                        <input
                          type="text"
                          placeholder=""
                          value={claimsThree}
                          onChange={(e) => setClaimsThree(e.target.value)}
                        />
                      </div>

                      <div className="input">
                        <label>Benefits </label>
                        <input
                          type="text"
                          placeholder=""
                          value={benefits}
                          onChange={(e) => setBenefits(e.target.value)}
                        />
                      </div>
                      <div className="input">
                        <label>Product description English</label>
                        <input
                          type="text"
                          placeholder=""
                          value={proDesEnglish}
                          onChange={(e) => setProDesEnglish(e.target.value)}
                        />
                      </div>

                      <div className="input">
                        <label>Product Description Arbic</label>
                        <textarea
                          type="text"
                          placeholder="Savor the trio of decadence with our"
                          value={proDesArbic}
                          onChange={(e) => setProDesArbic(e.target.value)}
                        />
                      </div>

                      <div className="input">
                        <label>Dimensions per Case</label>
                        <input
                          type="text"
                          placeholder=""
                          value={dimensionsPerCase}
                          onChange={(e) => setDimensionsPerCase(e.target.value)}
                        />
                      </div>

                      <div className="input">
                        <label>Storage Instructions</label>
                        <textarea
                          type="text"
                          placeholder=""
                          value={storageInstructions}
                          onChange={(e) =>
                            setStorageInstructions(e.target.value)
                          }
                        />
                      </div>

                      <div className="input">
                        <label>Shelf Life</label>
                        <input
                          type="text"
                          placeholder=""
                          value={shelfLife}
                          onChange={(e) => setShelfLife(e.target.value)}
                        />
                      </div>
                      <div className="form_in">
                        <div className="input">
                          <label>Unit Width (mm)</label>
                          <input
                            type="text"
                            placeholder=""
                            value={widthMM}
                            onChange={(e) => setWidthMM(e.target.value)}
                          />
                        </div>
                        <div className="input">
                          <label>Unit Height (mm)</label>
                          <input
                            type="text"
                            placeholder=""
                            value={heightMM}
                            onChange={(e) => setHeightMM(e.target.value)}
                          />
                        </div>
                        <div className="input">
                          <label>Unit Length (mm)</label>
                          <input
                            type="text"
                            placeholder=""
                            value={lengthMM}
                            onChange={(e) => setLengthMM(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form_in">
                        <div className="input">
                          <label>CTN Width (mm)</label>
                          <input
                            type="text"
                            placeholder=""
                            value={ctnWidthMM}
                            onChange={(e) => setCtnWidthMM(e.target.value)}
                          />
                        </div>
                        <div className="input">
                          <label>CTN Height (mm)</label>
                          <input
                            type="text"
                            placeholder=""
                            value={ctnHeightMM}
                            onChange={(e) => setCtnHeightMM(e.target.value)}
                          />
                        </div>
                        <div className="input">
                          <label>CTN Length (mm)</label>
                          <input
                            type="text"
                            placeholder=""
                            value={ctnLengthMM}
                            onChange={(e) => setCtnLengthMM(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="input">
                        <label>Ingredients</label>
                        <input
                          type="text"
                          placeholder=""
                          value={ingredients}
                          onChange={(e) => setIngredients(e.target.value)}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="input">
                        <h2>Only e-Com images upload below</h2>
                        <br />
                        <div className="photo_grid">
                          {addedPhotos.length > 0 &&
                            addedPhotos.map((item, index) => (
                              <div className="img_over" key={index}>
                                <img
                                  src={`https://api-master.notionsgroup.com/uploads/${item}`}
                                  alt=""
                                />
                                {/* <img
                                            src={`${imageUrl}/${item}`}
                                            alt=""
                                          /> */}

                                <div className="trash_icon">
                                  <button onClick={(e) => removePhoto(e, item)}>
                                    <DeleteOutlineIcon
                                      style={{
                                        fontSize: "medium",
                                      }}
                                    />
                                  </button>
                                </div>
                                <div className="star_icon">
                                  <button
                                    onClick={(e) => selectMainPhoto(e, item)}
                                  >
                                    {item === addedPhotos[0] && (
                                      <StarIcon
                                        style={{
                                          fontSize: "medium",
                                          color: "orange",
                                        }}
                                      />
                                    )}
                                    {item !== addedPhotos[0] && (
                                      <StarBorderIcon
                                        style={{
                                          fontSize: "medium",
                                        }}
                                      />
                                    )}
                                  </button>
                                </div>
                              </div>
                            ))}
                          <label>
                            <input
                              onChange={uploadPhoto}
                              type="file"
                              multiple
                              className="btn_upload"
                            />
                            {loading ? <>Please wait...</> : <>Upload Images</>}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div>
                      <h2>Only HD images upload below</h2>
                      <br />
                      <div className="input">
                        <div className="photo_grid">
                          {addedPhotosHD.length > 0 &&
                            addedPhotosHD.map((item, index) => (
                              <div className="img_over" key={index}>
                                <img
                                  src={`https://api-master.notionsgroup.com/HDuploads/${item}`}
                                  alt=""
                                />

                                <div className="trash_icon">
                                  <button
                                    onClick={(e) => removePhotoHD(e, item)}
                                  >
                                    <DeleteOutlineIcon
                                      style={{
                                        fontSize: "medium",
                                      }}
                                    />
                                  </button>
                                </div>
                                <div className="star_icon">
                                  <button
                                    onClick={(e) => selectMainPhotoHD(e, item)}
                                  >
                                    {item === addedPhotosHD[0] && (
                                      <StarIcon
                                        style={{
                                          fontSize: "medium",
                                          color: "orange",
                                        }}
                                      />
                                    )}
                                    {item !== addedPhotosHD[0] && (
                                      <StarBorderIcon
                                        style={{
                                          fontSize: "medium",
                                        }}
                                      />
                                    )}
                                  </button>
                                </div>
                              </div>
                            ))}
                          <label className="img_upload">
                            <input
                              onChange={uploadPhotoHD}
                              type="file"
                              multiple
                              className="btn_upload"
                            />
                            Upload HD Images
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="save">
                    <button>
                      <span>Save</span>
                    </button>
                  </div>
                </div>
                <Link to={"/inventory"}>
                  <div className="close">
                    <span onClick={handleAddNew}>X</span>
                  </div>
                </Link>
              </form>
            </div>
          </div>{" "}
          <div className="addNew_in">
            <div className="addNew_wrap">
              <h2>Add New Product</h2>
              <br />

              <form onSubmit={handleSubmit}>
                <div className="add_form">
                  <div className="add_form_wrap">
                    <div>
                      <div className="input">
                        <label>01. Title</label>
                        <input
                          type="text"
                          placeholder="Chocodate"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                      <div className="input">
                        <label>Price</label>
                        <input
                          type="text"
                          placeholder="100 AED"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                        />
                      </div>
                      <div className="input">
                        <label>Weight</label>
                        <input
                          type="text"
                          placeholder="500g"
                          value={weight}
                          onChange={(e) => setWeight(e.target.value)}
                        />
                      </div>
                      <div className="input">
                        <label>Unit</label>
                        <select
                          value={unit}
                          onChange={(e) => setUnit(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="ACRYLIC">ACRYLIC</option>
                          <option value="BOX">BOX</option>
                          <option value="BULK">BULK</option>
                          <option value="CUBE">CUBE</option>
                          <option value="JAR">JAR</option>
                          <option value="POUCH">POUCH</option>
                          <option value="T BOX">T BOX</option>
                        </select>
                      </div>

                      <div className="input">
                        <label>Type</label>
                        <select
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="BULK">BULK</option>
                          <option value="PROM">PROM</option>
                          <option value="REG">REG</option>
                        </select>
                      </div>

                      <div className="input">
                        <label>Category</label>
                        <select
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="BAKING">BAKING</option>
                          <option value="CHOCOLATE">CHOCOLATE</option>
                          <option value="CHOCOLATE DATES">
                            CHOCOLATE DATES
                          </option>
                          <option value="COATEED NUTS">COATEED NUTS</option>
                          <option value="ENERGY & PROTIEN">
                            ENERGY & PROTIEN
                          </option>
                          <option value="PLAIN DATE">PLAIN DATE</option>
                        </select>
                      </div>

                      <div className="input">
                        <label>Sub Category</label>
                        <select
                          value={subCategory}
                          onChange={(e) => setSubCategory(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="BUTTER">BUTTER</option>
                          <option value="CHOCOLATE">CHOCOLATE</option>
                          <option value="CHOCOLATE APRICOT">
                            CHOCOLATE APRICOT
                          </option>
                          <option value="CHOCOLATE CHIPS">
                            CHOCOLATE CHIPS
                          </option>
                          <option value="CHOCOLATE FIG">CHOCOLATE FIG</option>
                          <option value="COATED NUTS">COATED-NUTS</option>
                          <option value="DATE CHOCO">DATE-CHOCO</option>
                          <option value="FLOUR">FLOUR</option>
                          <option value="PLAIN DATE">PLAIN DATE</option>
                          <option value="PROTEIN BALL">PROTEIN BALL</option>
                          <option value="SUGAR">SUGAR</option>
                        </select>
                      </div>

                      <div className="input">
                        <label>Brand</label>
                        <select
                          value={brand}
                          onChange={(e) => setBrand(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="ALDI">ALDI</option>
                          <option value="APRONS N LOVE">APRONS N LOVE</option>
                          <option value="ARABIAN DELIGHTS">
                            ARABIAN DELIGHTS
                          </option>
                          <option value="ATT">ATT</option>
                          <option value="CARREFOUR">CARREFOUR</option>
                          <option value="CHOCODATE">CHOCODATE</option>
                          <option value="DARA">DARA</option>
                          <option value="FADE FIT">FADE FIT</option>
                          <option value="FIESTA">FIESTA</option>
                          <option value="HERITAGE">HERITAGE</option>
                          <option value="KITCHEN & LOVE">KITCHEN & LOVE</option>
                          <option value="LULU">LULU</option>
                          <option value="MARS">MARS</option>
                          <option value="MOJO">MOJO</option>
                          <option value="OTHERS">OTHERS</option>
                          <option value="ROUGE">ROUGE</option>
                          <option value="SPINNEYS">SPINNEYS</option>
                          <option value="VENICE">VENICE</option>
                          <option value="WISHES">WISHES</option>
                          <option value="ZEIN">ZEIN</option>
                        </select>
                      </div>

                      <div className="input">
                        <label>Brand Owner</label>
                        <select
                          value={brandOwner}
                          onChange={(e) => setBrandOwner(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="NOTIONS">NOTIONS</option>
                          <option value="PRIVATE LABEL">PRIVATE LABEL</option>
                        </select>
                      </div>

                      <div className="input">
                        <label>Base</label>
                        <select
                          value={base}
                          onChange={(e) => setBase(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="ALMOND">ALMOND</option>
                          <option value="APRICOT">APRICOT</option>
                          <option value="BUTTER">BUTTER</option>
                          <option value="CHOCOLATE">CHOCOLATE</option>
                          <option value="CHOCOLATE CHIPS">
                            CHOCOLATE CHIPS
                          </option>
                          <option value="DATE">DATE</option>
                          <option value="DATE PASTE">DATE PASTE</option>
                          <option value="DATES">DATES</option>
                          <option value="FIG">FIG</option>
                          <option value="FLOUR">FLOUR</option>
                          <option value="HAZELNUT">HAZELNUT</option>
                          <option value="PLAIN">PLAIN</option>
                          <option value="PROTEIN BALL">PROTEIN BALL</option>
                          <option value="SUGAR">SUGAR</option>
                        </select>
                      </div>

                      <div className="input">
                        <label>Filling </label>
                        <select
                          value={filling}
                          onChange={(e) => setFilling(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="0">0</option>
                          <option value="ALMOND">ALMOND</option>
                          <option value="ASSORTED">ASSORTED</option>
                          <option value="BERRY">BERRY</option>
                          <option value="CARAMEL">CARAMEL</option>
                          <option value="CHOCOLATE CHIPS">
                            CHOCOLATE CHIPS
                          </option>
                          <option value="CHOCOLATE">CHOCOLATE</option>
                          <option value="CHOCOLATES">CHOCOLATES</option>
                          <option value="COCONUT">COCONUT</option>
                          <option value="FRUITS">FRUITS</option>
                          <option value="HAZELNUTS">HAZELNUTS</option>
                          <option value="HAZELNUT">HAZELNUT</option>
                          <option value="MOUSSE">MOUSSE</option>
                          <option value="NUTS">NUTS</option>
                          <option value="ORANGE">ORANGE</option>
                          <option value="PEANUT">PEANUT</option>
                          <option value="PEANUT BUTTER">PEANUT BUTTER</option>
                          <option value="PISTACHIO">PISTACHIO</option>
                          <option value="PLAIN">PLAIN</option>
                          <option value="TRUFFLE">TRUFFLE</option>
                        </select>
                      </div>

                      <div className="input">
                        <label>Coating </label>
                        <select
                          value={coating}
                          onChange={(e) => setCoating(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="ALMOND">ALMOND</option>
                          <option value="ASSORTED">ASSORTED</option>
                          <option value="BUTTERSCOTCH">BUTTERSCOTCH</option>
                          <option value="CARAMEL">CARAMEL</option>
                          <option value="DARK">DARK</option>
                          <option value="EXTRA DARK">EXTRA DARK</option>
                          <option value="HAZELNUT">HAZELNUT</option>
                          <option value="COCONUT">COCONUT</option>
                          <option value="MANGO">MANGO</option>
                          <option value="MILK">MILK</option>
                          <option value="NAS">NAS</option>
                          <option value="ORANGE">ORANGE</option>
                          <option value="PEANUT">PEANUT</option>
                          <option value="PISTACHIO">PISTACHIO</option>
                          <option value="PLAIN">PLAIN</option>
                          <option value="RUBY">RUBY</option>
                          <option value="SEMI SWEET">SEMI SWEET</option>

                          <option value="WHITE">WHITE</option>
                        </select>
                      </div>
                      <div className="input">
                        <label>WET Rang</label>
                        <select
                          value={wetRang}
                          onChange={(e) => setWetRang(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="BULK">BULK</option>
                          <option value="L">L</option>
                          <option value="M">M</option>
                          <option value="MINI">MINI</option>
                          <option value="S">S</option>
                        </select>
                      </div>

                      <div className="input">
                        <label>SKU</label>
                        <input
                          type="text"
                          placeholder="LR 123456"
                          value={sku}
                          onChange={(e) => setSku(e.target.value)}
                        />
                      </div>

                      <div className="input">
                        <label>SKU Name English</label>
                        <input
                          type="text"
                          placeholder=""
                          value={nameEnglish}
                          onChange={(e) => setNameEnglish(e.target.value)}
                        />
                      </div>
                      <div className="input">
                        <label>SKU Name Arbic</label>
                        <input
                          type="text"
                          placeholder=""
                          value={nameArbic}
                          onChange={(e) => setNameArbic(e.target.value)}
                        />
                      </div>
                      <div className="input">
                        <label>Barcode - Pouch</label>
                        <input
                          type="text"
                          placeholder=""
                          value={barcodePouch}
                          onChange={(e) => setBarcodePouch(e.target.value)}
                        />
                      </div>
                      <div className="input">
                        <label>Barcode - Case</label>
                        <input
                          type="text"
                          placeholder=""
                          value={barcodeCase}
                          onChange={(e) => setBarcodeCase(e.target.value)}
                        />
                      </div>
                      <div className="input">
                        <label>Claims 1</label>
                        <input
                          type="text"
                          placeholder=""
                          value={claimsOne}
                          onChange={(e) => setClaimsOne(e.target.value)}
                        />
                      </div>
                      <div className="input">
                        <label>Claims 2</label>
                        <input
                          type="text"
                          placeholder=""
                          value={claimsTwo}
                          onChange={(e) => setClaimsTwo(e.target.value)}
                        />
                      </div>
                      <div className="input">
                        <label>Claims 3</label>
                        <input
                          type="text"
                          placeholder=""
                          value={claimsThree}
                          onChange={(e) => setClaimsThree(e.target.value)}
                        />
                      </div>

                      <div className="input">
                        <label>Benefits </label>
                        <input
                          type="text"
                          placeholder=""
                          value={benefits}
                          onChange={(e) => setBenefits(e.target.value)}
                        />
                      </div>
                      <div className="input">
                        <label>Product description English</label>
                        <input
                          type="text"
                          placeholder=""
                          value={proDesEnglish}
                          onChange={(e) => setProDesEnglish(e.target.value)}
                        />
                      </div>

                      <div className="input">
                        <label>Product Description Arbic</label>
                        <textarea
                          type="text"
                          placeholder="Savor the trio of decadence with our"
                          value={proDesArbic}
                          onChange={(e) => setProDesArbic(e.target.value)}
                        />
                      </div>

                      <div className="input">
                        <label>Dimensions per Case</label>
                        <input
                          type="text"
                          placeholder=""
                          value={dimensionsPerCase}
                          onChange={(e) => setDimensionsPerCase(e.target.value)}
                        />
                      </div>

                      <div className="input">
                        <label>Storage Instructions</label>
                        <textarea
                          type="text"
                          placeholder=""
                          value={storageInstructions}
                          onChange={(e) =>
                            setStorageInstructions(e.target.value)
                          }
                        />
                      </div>

                      <div className="input">
                        <label>Shelf Life</label>
                        <input
                          type="text"
                          placeholder=""
                          value={shelfLife}
                          onChange={(e) => setShelfLife(e.target.value)}
                        />
                      </div>
                      <div className="form_in">
                        <div className="input">
                          <label>Unit Width (mm)</label>
                          <input
                            type="text"
                            placeholder=""
                            value={widthMM}
                            onChange={(e) => setWidthMM(e.target.value)}
                          />
                        </div>
                        <div className="input">
                          <label>Unit Height (mm)</label>
                          <input
                            type="text"
                            placeholder=""
                            value={heightMM}
                            onChange={(e) => setHeightMM(e.target.value)}
                          />
                        </div>
                        <div className="input">
                          <label>Unit Length (mm)</label>
                          <input
                            type="text"
                            placeholder=""
                            value={lengthMM}
                            onChange={(e) => setLengthMM(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form_in">
                        <div className="input">
                          <label>CTN Width (mm)</label>
                          <input
                            type="text"
                            placeholder=""
                            value={ctnWidthMM}
                            onChange={(e) => setCtnWidthMM(e.target.value)}
                          />
                        </div>
                        <div className="input">
                          <label>CTN Height (mm)</label>
                          <input
                            type="text"
                            placeholder=""
                            value={ctnHeightMM}
                            onChange={(e) => setCtnHeightMM(e.target.value)}
                          />
                        </div>
                        <div className="input">
                          <label>CTN Length (mm)</label>
                          <input
                            type="text"
                            placeholder=""
                            value={ctnLengthMM}
                            onChange={(e) => setCtnLengthMM(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="input">
                        <label>Ingredients</label>
                        <input
                          type="text"
                          placeholder=""
                          value={ingredients}
                          onChange={(e) => setIngredients(e.target.value)}
                        />
                      </div>
                    </div>

                    <div>
                      <div className="approve_wrp">
                        <div className="approve_wrp_head">
                          <h4>Product Approval</h4>
                        </div>
                        <div className="ap_wrp">
                          <div className="ap_item">
                            <span>
                              {approve_one === true ? (
                                <>
                                  <IoCheckmarkCircle size={25} color="23C552" />
                                </>
                              ) : (
                                <>
                                  <IoIosCloseCircle size={25} color="F84F31" />
                                </>
                              )}
                            </span>
                            {approve_one === false ? (
                              <button
                                className="btn_logout"
                                onClick={() => handleApproveOne()}
                              >
                                One
                              </button>
                            ) : (
                              <button disabled className="_btn_logout">
                                One
                              </button>
                            )}
                          </div>
                          <div className="ap_item">
                            <span>
                              {approve_two === true ? (
                                <span>
                                  <IoCheckmarkCircle size={25} color="23C552" />
                                </span>
                              ) : (
                                <span>
                                  <IoIosCloseCircle size={25} color="F84F31" />
                                </span>
                              )}
                            </span>
                            {approve_two === false ? (
                              <button
                                className="btn_logout"
                                onClick={() => handleApproveTwo()}
                              >
                                Two
                              </button>
                            ) : (
                              <button disabled className="_btn_logout">
                                Two
                              </button>
                            )}
                          </div>
                          <div className="ap_item">
                            <span>
                              {approve_three === true ? (
                                <>
                                  <IoCheckmarkCircle size={25} color="23C552" />
                                </>
                              ) : (
                                <>
                                  <IoIosCloseCircle size={25} color="F84F31" />
                                </>
                              )}
                            </span>
                            {approve_three === false ? (
                              <button
                                className="btn_logout"
                                onClick={() => handleApproveThree()}
                              >
                                Three
                              </button>
                            ) : (
                              <button disabled className="_btn_logout">
                                Three
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      <h4>Only HD images upload below</h4>
                      <br />
                      <div className="input">
                        <div className="photo_grid">
                          {addedPhotosHD.length > 0 &&
                            addedPhotosHD.map((item, index) => (
                              <div className="img_over" key={index}>
                                <img
                                  src={`https://api-master.notionsgroup.com/HDuploads/${item}`}
                                  alt=""
                                />

                                <div className="trash_icon">
                                  <button
                                    onClick={(e) => removePhotoHD(e, item)}
                                  >
                                    <DeleteOutlineIcon
                                      style={{
                                        fontSize: "medium",
                                      }}
                                    />
                                  </button>
                                </div>
                                <div className="star_icon">
                                  <button
                                    onClick={(e) => selectMainPhotoHD(e, item)}
                                  >
                                    {item === addedPhotosHD[0] && (
                                      <StarIcon
                                        style={{
                                          fontSize: "medium",
                                          color: "orange",
                                        }}
                                      />
                                    )}
                                    {item !== addedPhotosHD[0] && (
                                      <StarBorderIcon
                                        style={{
                                          fontSize: "medium",
                                        }}
                                      />
                                    )}
                                  </button>
                                </div>
                              </div>
                            ))}
                          <label className="img_upload">
                            <input
                              onChange={uploadPhotoHD}
                              type="file"
                              multiple
                              className="btn_upload"
                            />
                            Upload HD Images
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="input">
                        <h2>Only e-Com images upload below</h2>
                        <br />
                        <div className="photo_grid">
                          {addedPhotos.length > 0 &&
                            addedPhotos.map((item, index) => (
                              <div className="img_over" key={index}>
                                <img
                                  src={`https://api-master.notionsgroup.com/uploads/${item}`}
                                  alt=""
                                />
                                {/* <img
                                            src={`${imageUrl}/${item}`}
                                            alt=""
                                          /> */}

                                <div className="trash_icon">
                                  <button onClick={(e) => removePhoto(e, item)}>
                                    <DeleteOutlineIcon
                                      style={{
                                        fontSize: "medium",
                                      }}
                                    />
                                  </button>
                                </div>
                                <div className="star_icon">
                                  <button
                                    onClick={(e) => selectMainPhoto(e, item)}
                                  >
                                    {item === addedPhotos[0] && (
                                      <StarIcon
                                        style={{
                                          fontSize: "medium",
                                          color: "orange",
                                        }}
                                      />
                                    )}
                                    {item !== addedPhotos[0] && (
                                      <StarBorderIcon
                                        style={{
                                          fontSize: "medium",
                                        }}
                                      />
                                    )}
                                  </button>
                                </div>
                              </div>
                            ))}
                          <label>
                            <input
                              onChange={uploadPhoto}
                              type="file"
                              multiple
                              className="btn_upload"
                            />
                            {loading ? <>Please wait...</> : <>Upload Images</>}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="save">
                    <button>
                      <span>Save</span>
                    </button>
                  </div>
                </div>
                <Link to={"/inventory"}>
                  <div className="close">
                    <span onClick={handleAddNew}>X</span>
                  </div>
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNew;
